export function categorizeAge(age) {
  switch (true) {
    case age >= 1 && age <= 12:
      return "enfant";
    case age >= 13 && age <= 19:
      return "adolescent";
    case age >= 20 && age <= 99:
      return "adulte";
    default:
      return "Invalid age";
  }
}
