import axios from "axios";

const url = process.env.REACT_APP_API_URL;
//const url = "http://localhost:8080";

/**
 * @param {String[]} interests
 * @returns {Promise<{runId: string; threadId: string}>}
 */
export const initThread = async (start, goal, nbHours, interests, category) => {
  const response = await axios.post(`${url}/itinerary`, {
    text: `Coucou. Remplis moi de fichier JSON avec tes informations sur les POI parisiens que tu peux trouver en ligne, et affiche le dans ta réponse. 
    Je veux qu'il soit affiché dans un format JSON, pas que tu me proposes de le télécharger.
    Le format est UTF-8
    
    {
      "introduction": "Présente l'itinéraire global, et chacun de ses arrêts. 150 mots minimum",
      "tour": [
        {
          "latitude": 0,
          "longitude": 0,
          "name": "nom du POI",
          "address": "addresse du POI",
          "description": "courte description, 80 mots minimum",
          "walkingTime": "temps de marche depuis le précédent POI au format hh:mm",
          "visitDuration": "durée de la visite"
        }
      ],
      "totalTime": "total de tous les temps de marche et de visite des POI sélectionnés au format hh:mm"
    }
    
    Je suis un ${category}, j'aime les ${interests}, je pars de ${start} et je vais à ${goal}. J'ai ${nbHours}h de promenade à disposition.`,
  });
  console.log(response.data);
  return response.data;
};

export const getMessages = async (threadId) => {
  const response = await axios.get(`${url}/itinerary/messages/${threadId}`);
  return response.data;
};

export const sendNewMessage = async (threadId, userMsg) => {
  const response = await axios.post(`${url}/itinerary/messages/${threadId}`, {
    text: userMsg,
  });
  console.log(response);
  return response.data;
};

export const getPoiSpeech = async (text, poiName) => {
  try {
    const response = await axios.post(
      `${url}/tts`,
      {
        text: text,
        poiName: poiName,
      },
      {
        responseType: "blob",
      },
    );
    const blob = new Blob([response.data], { type: "audio/mp3" });
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error fetching mp3:", error);
  }
};
