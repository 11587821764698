import React, { Fragment } from "react";
import facts from "../../data/funFacts.json";
import guide from "../../assets/guide.svg";

function LoadingView({ form }) {
  const getRandomFact = () => {
    const selectedFact =
      facts[Math.floor(Math.random() * (facts.length - 1 + 1) + 1) - 1];
    return selectedFact.text;
  };

  return (
    <Fragment>
      <div className="relative flex justify-center items-center mt-4">
        <div
          class="absolute -top-1 rounded-full animate-spin h-40 w-40"
          id="loading_guy"
        ></div>
        <img
          src={guide}
          alt="un gentil guide"
          className="mx-auto mb-8 h-36 w-36 rounded-full"
        />
      </div>
      <h2 className="font-poppins text-txt-main text-xl w-full text-center">
        Le saviez vous ?
      </h2>
      <p className="mt-4 mb-8 font-poppins text-txt-main text-justify">
        {getRandomFact()}
      </p>
      <div className="bg-bg-secondary rounded-lg text-txt-main font-poppins px-4 py-3">
        <div className="flex justify-between items-center px-4 mb-4">
          <p>Départ</p>
          <p>{form.start}</p>
        </div>
        <div className="flex justify-between items-center px-4 my-6">
          <p>Arrivée</p>
          <p>{form.goal}</p>
        </div>
        <div className="flex justify-between items-center px-4 my-6">
          <p>Temps disponible</p>
          <p>{`${form.nbHours}h`}</p>
        </div>
        <div className="px-4 mt-6">
          <p className="mb-3">Centres d'intérêt</p>
          <div className="grid grid-cols-2 gap-3">
            {form.interests.map((interest, index) => {
              return (
                <p
                  className="w-full text-center bg-bg-main p-4 rounded-md capitalize"
                  key={index}
                >
                  {interest}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <p className="animate-pulse bg-[#EBEBEB] mt-6 text-center p-2 font-poppins mb-16">
        Chargement
      </p>
    </Fragment>
  );
}

export default LoadingView;
