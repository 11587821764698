import React from "react";
import loader from "../assets/Loader.gif";

function Button({ buttonText, onClickAction, loading }) {
  return (
    <button
      className="w-full bg-bg-focus text-txt-secondary rounded-lg h-10 flex justify-center items-center"
      onClick={() => onClickAction()}
      disabled={loading}
    >
      {!loading && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      )}
      {loading ? (
        <div className="ml-2">
          <img alt="loader" src={loader} className="h-6 w-6" />
        </div>
      ) : (
        <span className="ml-2">{buttonText}</span>
      )}
    </button>
  );
}

export default Button;
