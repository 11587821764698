import React, { Fragment } from "react";

function PathwayView({ tour }) {
  return (
    <Fragment>
      <p className="mb-8 mt-2 text-txt-main font-poppins">
        {tour.introduction}
      </p>
      <h2 className="text-bg-focus font-medium text-xl my-4 font-poppins">
        Détails
      </h2>
      {tour.tour.map((poi, index) => {
        return (
          <div key={index} className="my-4">
            <h3>
              <span className="text-bg-focus font-poppins">{index + 1}.</span>{" "}
              {poi.name}
            </h3>
            <p className="my-2 font-poppins">{poi.howToGo}</p>
          </div>
        );
      })}
    </Fragment>
  );
}

export default PathwayView;
