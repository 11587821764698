import React, { useState } from "react";

function FormSelect({ label, formKey, handler }) {
  const [interests, setInterests] = useState([]);

  const handleUpdate = (value) => {
    let newInterests = [...interests];
    if (newInterests.includes(value)) {
      newInterests.splice(newInterests.indexOf(value), 1);
    } else {
      newInterests.push(value);
    }
    setInterests(newInterests);
    handler(newInterests, formKey);
  };

  return (
    <div className="rounded-lg p-4 bg-bg-secondary mb-4 h-60">
      <h2 className="mb-2">{label}</h2>
      <div className="grid grid-cols-2 gap-4">
        <div
          className={`cursor-pointer rounded-lg flex flex-col items-center h-[84px] p-3 hover:text-txt-secondary hover:bg-bg-focus ${interests.includes("architecture") ? "text-txt-secondary bg-bg-focus" : "text-bg-focus bg-bg-main"}`}
          onClick={() => handleUpdate("architecture")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`w-8 h-8`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
            />
          </svg>
          <span className="font-semibold font-poppins">Architecture</span>
        </div>
        <div
          className={`cursor-pointer rounded-lg flex flex-col items-center h-[84px] p-3 hover:text-txt-secondary hover:bg-bg-focus ${interests.includes("parcs") ? "text-txt-secondary bg-bg-focus" : "text-bg-focus bg-bg-main"}`}
          onClick={() => handleUpdate("parcs")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
            />
          </svg>
          <span className="font-semibold font-poppins">Parcs</span>
        </div>
        <div
          className={`cursor-pointer rounded-lg flex flex-col items-center h-[84px] p-3 hover:text-txt-secondary hover:bg-bg-focus ${interests.includes("culture") ? "text-txt-secondary bg-bg-focus" : "text-bg-focus bg-bg-main"}`}
          onClick={() => handleUpdate("culture")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
            />
          </svg>

          <span className="font-semibold font-poppins">Culture</span>
        </div>
        <div
          className={`cursor-pointer rounded-lg flex flex-col items-center h-[84px] p-3 hover:text-txt-secondary hover:bg-bg-focus ${interests.includes("musées") ? "text-txt-secondary bg-bg-focus" : "text-bg-focus bg-bg-main"}`}
          onClick={() => handleUpdate("musées")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m20.893 13.393-1.135-1.135a2.252 2.252 0 0 1-.421-.585l-1.08-2.16a.414.414 0 0 0-.663-.107.827.827 0 0 1-.812.21l-1.273-.363a.89.89 0 0 0-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 0 1-1.81 1.025 1.055 1.055 0 0 1-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 0 1-1.383-2.46l.007-.042a2.25 2.25 0 0 1 .29-.787l.09-.15a2.25 2.25 0 0 1 2.37-1.048l1.178.236a1.125 1.125 0 0 0 1.302-.795l.208-.73a1.125 1.125 0 0 0-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 0 1-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 0 1-1.458-1.137l1.411-2.353a2.25 2.25 0 0 0 .286-.76m11.928 9.869A9 9 0 0 0 8.965 3.525m11.928 9.868A9 9 0 1 1 8.965 3.525"
            />
          </svg>

          <span className="font-semibold font-poppins">Musées</span>
        </div>
      </div>
    </div>
  );
}

export default FormSelect;
