import React, { useState, useEffect } from "react";
import { Layer, Map, Marker, NavigationControl, Source } from "react-map-gl";
import { getPoiSpeech } from "../../utils/api";
import PathwayView from "./PathwayView";
import AudioView from "./AudioView";

function TourView({ tour, getBackToForm }) {
  const [pois, setPois] = useState([]);
  const [playedIndex, setPlayedIndex] = useState();
  const [audioURL, setAudioURL] = useState();
  const [isInfoSelected, setIsInfoSelected] = useState(true);
  const [isAudioLoading, setIsAudioLoading] = useState();

  useEffect(() => {
    const newList = [];
    tour.tour.map((poi) => newList.push([poi.longitude, poi.latitude]));
    setPois(newList);
  }, [tour]);

  useEffect(() => {
    const audio = new Audio(audioURL);
    if (playedIndex !== null && audioURL) {
      console.log(audio);
      audio.play();
    }
    return () => {
      audio.pause();
    };
  }, [playedIndex, audioURL]);

  const handlePlay = async (index) => {
    setIsAudioLoading(index);
    if (playedIndex === index) {
      setPlayedIndex(null);
    } else {
      const url = await getPoiSpeech(
        tour.tour[index].description,
        tour.tour[index].name,
      );
      setAudioURL(url);
      setPlayedIndex(index);
    }
    setIsAudioLoading(null);
  };

  const dataTour = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: pois,
    },
  };

  return (
    <div>
      <div className="w-full mt-8 aspect-[3/2]">
        <Map
          mapLib={import("mapbox-gl")}
          initialViewState={{
            longitude: 2.333333,
            latitude: 48.866667,
            zoom: 11,
          }}
          style={{
            width: "100%",
            height: "100%",
            overflow: "clip",
            borderRadius: "8px",
          }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken="pk.eyJ1Ijoib3JrZXN0ZXIiLCJhIjoiY2xmOXViaTlsMDRjMDN2cWRxNXdudGNxbCJ9.RMj47osJs490d0tBsLsM1Q"
        >
          <NavigationControl showCompass={false}></NavigationControl>
          <Source id="polylineLayer" type="geojson" data={dataTour}>
            <Layer
              id="lineLayer"
              type="line"
              layout={{
                "line-join": "round",
                "line-cap": "round",
              }}
              source="my-data"
              paint={{
                "line-color": "#058828",
                "line-width": 5,
              }}
            />
          </Source>
          {tour.tour.map((poi, index) => (
            <Marker
              key={index}
              longitude={poi.longitude}
              latitude={poi.latitude}
              onClick={() => handlePlay(index)}
              style={
                playedIndex === index ? { padding: "0px" } : { padding: "2px" }
              }
            >
              {playedIndex === index ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 text-txt-secondary bg-bg-focus rounded-xl"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                  />
                </svg>
              )}
            </Marker>
          ))}
        </Map>
        <button
          className="z-30 relative -top-[360px] left-4 bg-bg-secondary rounded-full p-2"
          onClick={() => getBackToForm()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
      </div>
      <div className="p-4 pt-0">
        <h1 className="font-medium text-xl font-poppins">{`De ${tour.tour[0].name} à ${tour.tour[tour.tour.length - 1].name}`}</h1>
        <h4 className="font-normal text-sm text-txt-main font-poppins">
          Parcours pédestre | Durée : {tour.totalTime}{" "}
        </h4>
        <div className="flex justify-around my-8 border-b-[1px]">
          <h3
            onClick={() => setIsInfoSelected(true)}
            className={`font-poppins pb-2 w-1/2 text-center cursor-pointer ${isInfoSelected ? "border-b-[1px] border-b-bg-focus text-bg-focus" : "hover:text-bg-focus"}`}
          >
            Infos
          </h3>
          <h3
            onClick={() => setIsInfoSelected(false)}
            className={`font-poppins pb-2 w-1/2 text-center cursor-pointer ${!isInfoSelected ? "border-b-[1px] border-b-bg-focus text-bg-focus" : "hover:text-bg-focus"}`}
          >
            Audioguide
          </h3>
        </div>
        {isInfoSelected ? (
          <PathwayView tour={tour} />
        ) : (
          <AudioView
            tour={tour}
            playedIndex={playedIndex}
            isAudioLoading={isAudioLoading}
            handlePlay={handlePlay}
          />
        )}
      </div>
    </div>
  );
}

export default TourView;
