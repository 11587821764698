export const jsonChecker = (json) => {
  let isValid = true;
  if (
    "introduction" in json === false ||
    "tour" in json === false ||
    "totalTime" in json === false
  ) {
    isValid = false;
  }
  return isValid;
};
