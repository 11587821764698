import { useState, Fragment } from "react";
import FormView from "./views/FormView/index";
import TourView from "./views/TourView/index";
import Header from "./ui/Header";
import LoadingView from "./views/FormView/LoadingView";

function App() {
  const [tour, setTour] = useState();
  const [status, setStatus] = useState("not ready");
  const [form, setForm] = useState();

  const renderContent = () => {
    switch (status) {
      case "loading":
        console.log("loading");
        return <LoadingView form={form} />;
      case "ready":
        console.log("ready");
        return (
          <TourView tour={tour} getBackToForm={() => setStatus("not ready")} />
        );
      default:
        console.log("not ready");
        return (
          <FormView
            updateTour={(newTour) => setTour(newTour)}
            setReady={(newStatus) => setStatus(newStatus)}
            updateForm={(newForm) => setForm(newForm)}
          />
        );
    }
  };

  return (
    <Fragment>
      <Header />
      <main
        className="bg-main flex flex-col h-[100svh] max-w-xl mx-auto justify-between bg-bg-main overflow-scroll "
        id="no-scrollbar"
        key={status}
      >
        {renderContent()}
      </main>
    </Fragment>
  );
}

export default App;
