import React, { Fragment } from "react";
import Loader from "../../ui/Loader";

function AudioView({ tour, playedIndex, isAudioLoading, handlePlay }) {
  return (
    <Fragment>
      {tour.tour.map((poi, index) => {
        return isAudioLoading === index ? (
          <div
            key={index}
            className="my-8 flex items-center justify-between px-4"
          >
            <h3
              className={`font-poppins font-normal ${playedIndex === index && "font-semibold"}`}
            >
              {poi.name}
            </h3>
            <Loader />
          </div>
        ) : (
          <div
            key={index}
            className="my-8 flex items-center justify-between px-4"
          >
            <h3
              className={`font-poppins font-normal ${playedIndex === index && "font-semibold"}`}
            >
              {poi.name}
            </h3>
            {playedIndex === index ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-10 h-10 p-2 bg-bg-secondary hover:bg-bg-focus text-txt-main hover:text-txt-secondary rounded-full cursor-pointer"
                onClick={() => handlePlay(index)}
              >
                <path
                  fillRule="evenodd"
                  d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-10 h-10 p-2 bg-bg-secondary hover:bg-bg-focus text-txt-main hover:text-txt-secondary rounded-full cursor-pointer"
                onClick={() => handlePlay(index)}
              >
                <path
                  fillRule="evenodd"
                  d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        );
      })}
    </Fragment>
  );
}

export default AudioView;
