import React, { useState, useEffect } from "react";
import Form from "../../ui/Form";
import FormNumber from "../../ui/FormNumber";
import Button from "../../ui/Button";
import FormSelect from "../../ui/FormSelect";
import { getMessages, initThread } from "../../utils/api";
import { keepFirstAndLastBraces } from "../../utils/stringFilter";
import { jsonChecker } from "../../utils/jsonChecker";
import guide from "../../assets/guide.svg";
import { categorizeAge } from "../../utils/ageCategory";

function FormView({ updateTour, updateForm, setReady }) {
  const [form, setForm] = useState();

  /**
   * @param {string | number} newValue
   * @param {string} key
   */
  const handleFormUpdate = (newValue, formKey) => {
    setForm((prevState) => ({ ...prevState, [formKey]: newValue }));
  };

  useEffect(() => {
    updateForm(form);
  }, [form, updateForm]);

  const handleInit = async (nbTry) => {
    try {
      setReady("loading");
      const category = categorizeAge(form.age);
      const logs = await initThread(
        form.start,
        form.goal,
        form.nbHours,
        form.interests,
        category,
      );
      const threadMsg = await getMessages(logs.threadId);
      console.log(threadMsg.data);
      const cleanString = keepFirstAndLastBraces(
        threadMsg.data[0].content[0].text.value,
      );
      console.log(cleanString);
      const jsonData = JSON.parse(cleanString);
      if (jsonChecker(jsonData)) {
        console.log(jsonData);
        updateTour(jsonData);
        setReady("ready");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      if (nbTry < 3) {
        handleInit(nbTry + 1);
      } else {
        setReady("not ready");
      }
    }
  };

  return (
    <div className="p-8">
      <div className="relative flex justify-center items-center">
        <div className="absolute -top-1 rounded-full h-40 w-40"></div>
        <img
          src={guide}
          alt="un gentil guide"
          className="mx-auto mb-8 h-36 w-36 rounded-full"
        />
      </div>
      <h1 className="font-poppins text-txt-main text-xl my-8 w-full text-center">
        Besoin d'aide pour planifier un itinéraire ?
      </h1>
      <Form
        label="D'où voulez vous partir ?"
        placeholder="Montmartre"
        shortLabel="Départ"
        formKey="start"
        handler={handleFormUpdate}
        startFocused={true}
      />
      <Form
        label="Où voulez vous arriver ?"
        placeholder="Le Marais"
        shortLabel="Arrivée"
        formKey="goal"
        handler={handleFormUpdate}
      />
      <FormNumber
        label="Temps disponible"
        placeholder="4h"
        shortLabel="Temps disponible"
        formKey="nbHours"
        handler={handleFormUpdate}
      />
      <FormNumber
        label="Âge"
        placeholder="35 ans"
        shortLabel="Âge"
        formKey="age"
        handler={handleFormUpdate}
        ageForm={true}
      />
      <FormSelect
        label="Centres d'intérêt"
        formKey="interests"
        handler={handleFormUpdate}
      />
      <Button buttonText="Rechercher" onClickAction={() => handleInit(1)} />
    </div>
  );
}

export default FormView;
