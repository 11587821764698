import React, { useState, useRef, useEffect } from "react";

function Form({
  label,
  placeholder,
  shortLabel,
  formKey,
  handler,
  startFocused = false,
}) {
  const [inputValue, setInputValue] = useState(null);
  const [isFocus, setIsFocus] = useState(startFocused);
  const formRef = useRef();

  const handleUpdate = (value) => {
    setInputValue(value);
    handler(value, formKey);
  };

  const handleClickOutside = (event) => {
    if (formRef && formRef !== null) {
      const cur = formRef.current;
      if (cur && !cur.contains(event.target)) {
        setIsFocus(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isFocus && formRef.current) {
      formRef.current.focus();
    }
  }, [isFocus]);

  return isFocus ? (
    <div className="bg-bg-focus text-txt-secondary p-3 pb-4 rounded-lg my-2">
      <p className="mb-2 font-poppins">{label}</p>
      <input
        type="text"
        placeholder={placeholder}
        className="p-2 w-full rounded-md text-black font-poppins"
        onChange={(event) => handleUpdate(event.target.value)}
        value={inputValue}
        ref={formRef}
      />
    </div>
  ) : (
    <div
      className="flex justify-between bg-bg-secondary items-center rounded-lg px-4 h-12 my-4 cursor-pointer"
      onClick={() => setIsFocus(true)}
    >
      <p className="font-poppins">{shortLabel}</p>
      {inputValue ? (
        <p className="font-poppins">{inputValue}</p>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      )}
    </div>
  );
}

export default Form;
